import React from "react";
import { locations } from "@contentful/app-sdk";
import ConfigScreen from "./locations/ConfigScreen";
import { useSDK } from "@contentful/react-apps-toolkit";
import { RedirectToEditor } from "./components/RedirectToEditor";

const App = () => {
  const sdk = useSDK();

  if (sdk.location.is(locations.LOCATION_APP_CONFIG)) {
    return <ConfigScreen />;
  }

  if (
    sdk.location.is(locations.LOCATION_ENTRY_FIELD) ||
    sdk.location.is(locations.LOCATION_DIALOG)
  ) {
    return <RedirectToEditor />;
  }

  return (
    <div
      style={{
        display: "grid",
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>Shopstory</div>
    </div>
  );
};

export default App;
