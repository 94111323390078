import { ConfigurationLink } from "./ConfigurationLink";
import { Spinner, Note, Paragraph } from "@contentful/f36-components";
import { useSDK } from "@contentful/react-apps-toolkit";
import { useEffect, useState } from "react";
import { FieldExtensionSDK } from "@contentful/app-sdk";

export const RedirectToEditor = () => {
  // @ts-ignore;
  const sdk = useSDK<FieldExtensionSDK>();

  const {
    parameters: { installation, instance, invocation },
  } = sdk;

  const [error, setError] = useState<any>();

  /**
   * The editorUrlFromFieldParam is only for the demo purpose where we want to have multiple URLs to canvas in a single space.
   *
   * In normal conditions the only URL taken into account is installation.editorURL because there is no editorUrl field param at all
   *
   * There are 3 potential sources of custom canvasUrl:
   * 1. invocation.canvasUrl -> (only for Dialog) invocation parameters are passed from Field extension to Dialog extension. Dialog extension per se doesn't have parameters.
   * 2. instance.canvasUrl -> (custom override) this is parameter from field (if filled).
   * 3. installation.canvasUrl -> global installation URL
   *
   */
  const editorUrlFromFieldParam =
    (invocation as any)?.canvasUrl ?? (instance as any)?.canvasUrl;
  const editorUrl = editorUrlFromFieldParam ?? installation.editorUrl;

  useEffect(() => {
    if (editorUrl) {
      try {
        const url = new URL(editorUrl);

        const accessToken = installation.accessToken;
        if (typeof accessToken === "string" && accessToken.trim() !== "") {
          url.searchParams.set("shopstoryAccessToken", accessToken);
        }

        url.searchParams.set("mode", "app");

        console.debug(
          "Access token: ",
          url.searchParams.get("shopstoryAccessToken")
        );
        console.debug("Redirecting to " + url.toString());

        window.location.href = url.toString();
      } catch (e) {
        // @ts-ignore
        setError(`${e.message} (${editorUrl})`);
      }
    }
  }, [editorUrl]);

  if (error) {
    return (
      <Note variant="negative" title={error}>
        <Paragraph>
          Please see the <ConfigurationLink /> to fix it.
        </Paragraph>
      </Note>
    );
  }

  if (!editorUrl) {
    return (
      <Note variant="primary" title="The Shopstory Editor URL is not defined.">
        <Paragraph>
          Please see the <ConfigurationLink /> to define it.
        </Paragraph>
      </Note>
    );
  }

  return <Spinner />;
};
