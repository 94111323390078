import { useSDK } from "@contentful/react-apps-toolkit";
import { TextLink } from "@contentful/f36-components";

export const ConfigurationLink = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const {
    ids: { environment, space, app },
  } = useSDK();

  return (
    <TextLink
      href={`https://app.contentful.com/spaces/${space}/environments/${environment}/apps/${app}`}
      target="_blank"
    >
      {children ? children : "configuration page"}
    </TextLink>
  );
};
